import React from 'react'
import ReactTimeAgo from 'react-time-ago'
import TimeAgo from 'javascript-time-ago'
import it from 'javascript-time-ago/locale/it.json'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { deleteTrainingDataById } from '../../api/trainingAi/trainingai';
import { Notify } from 'notiflix';
import { TrashIcon } from '@heroicons/react/solid';
import { setAllTrainingAiData, deleteSelectedData } from '../../redux/actions/trainingAiAction'


TimeAgo.addDefaultLocale(it)

function TrainingAiList({ id, name, url, time }) {

  const history = useHistory()
  var created_date = new Date(time);

  //redux
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const data = useSelector(state => state.trainingAi.allTrainingData)

  const deleteData = async () => {
    let response = deleteTrainingDataById(token, id);

    if (response) {
  
      dispatch(deleteSelectedData(id))

      Notify.success("Eliminato con successo ✨")
      

    } else {
      Notify.error("Errore nell'eliminazione 📛")
    }

  }


  return (
    <div className='flex justify-between'>
    <div onClick={() => history.push("/trainingai/" + id)} className='flex w-full items-center bg-gray-50 p-2 rounded-lg cursor-pointer'>
      <img className='w-16 rounded-lg' src={url} />
      <div className='flex flex-col justify-center px-4 border-r-2'>
        <p>{name}</p>
        
      </div>
      <div className='flex flex-col justify-center pl-4'>

        <ReactTimeAgo date={created_date} locale="it-IT" />
      </div>
    </div>
    <div className='flex justify-center'>
          <div onClick={() => deleteData()} className='bg-red-400 px-2 py-2 rounded-md w-32 text-center flex justify-center cursor-pointer hover:bg-red-300'>
            <p className='text-red-700 flex items-center'>Elimina <TrashIcon height={30} /></p>
          </div>
        </div>
    </div>
  )
}

export default TrainingAiList