import React from 'react'

export default function GridBackground() {

  
  return (

    <div className="grid-background absolute inset-0 p-2 grid grid-cols-12 gap-2 h-screen">

      {/* row 1 */}
      <div className="col-span-2 bg-gray-800 rounded animate-pulse delay-75 duration-75" style={{animationDuration:"2s"}}></div>
      <div className="col-span-5 bg-gray-800 rounded animate-pulse delay-200 duration-200" style={{animationDuration:"3s"}}></div>
      <div className="col-span-1 bg-gray-800 rounded animate-pulse delay-1000 duration-1000" style={{animationDuration:"5s"}}></div>
      <div className="col-span-4 bg-gray-800 rounded animate-pulse delay-100 duration-100" style={{animationDuration:"2s"}}></div>

      {/* row 2 */}
      <div className="col-span-5 bg-gray-800 rounded animate-pulse delay-75 duration-75" style={{animationDuration:"2s"}}></div>
      <div className="col-span-3 bg-gray-800 rounded animate-pulse delay-200 duration-200" style={{animationDuration:"5s"}}></div>
      <div className="col-span-2 bg-gray-800 rounded animate-pulse delay-1000 duration-1000" style={{animationDuration:"3s"}}></div>
      <div className="col-span-2 bg-gray-800 rounded animate-pulse delay-200 duration-200" style={{animationDuration:"7s"}}></div>

      {/* row 3 */}
      <div className="col-span-4 bg-gray-800 rounded animate-pulse delay-75 duration-75" style={{animationDuration:"2s"}}></div>
      <div className="col-span-7 bg-gray-800 rounded animate-pulse delay-200 duration-200" style={{animationDuration:"5s"}}></div>
      <div className="col-span-1 bg-gray-800 rounded animate-pulse delay-1000 duration-1000" style={{animationDuration:"6s"}}></div>

      {/* row 4 */}
      <div className="col-span-2 bg-gray-800 rounded animate-pulse delay-1000 duration-1000" style={{animationDuration:"4s"}}></div>
      <div className="col-span-4 bg-gray-800 rounded animate-pulse delay-75 duration-75" style={{animationDuration:"2s"}}></div>
      <div className="col-span-6 bg-gray-800 rounded animate-pulse delay-200 duration-200" style={{animationDuration:"5s"}}></div>

      {/* row 5 */}
      <div className="col-span-5 bg-gray-800 rounded animate-pulse delay-1000 duration-1000" style={{animationDuration:"6s"}}></div>
      <div className="col-span-5 bg-gray-800 rounded animate-pulse delay-200 duration-200" style={{animationDuration:"4s"}}></div>
      <div className="col-span-2 bg-gray-800 rounded animate-pulse delay-75 duration-75" style={{animationDuration:"2s"}}></div>

      {/* row 6 */}
      <div className="col-span-4 bg-gray-800 rounded animate-pulse delay-75 duration-75" style={{animationDuration:"7s"}}></div>
      <div className="col-span-7 bg-gray-800 rounded animate-pulse delay-200 duration-200" style={{animationDuration:"1s"}}></div>
      <div className="col-span-1 bg-gray-800 rounded animate-pulse delay-1000 duration-1000" style={{animationDuration:"3s"}}></div>


    </div>
  )
}
