import { ActionTypes } from "../constants/actionsType";

export const setAllTrainingAiData = (allTrainingAiData) => {
  return {
    type: ActionTypes.SET_TRAINING_AI_DATA,
    payload: allTrainingAiData
  };
};

export const setShowModal = (show) => {
  return {
    type: ActionTypes.SET_SHOW_MODAL_TRAINING,
    payload: show,
  };
};

export const setSelectedSeason = (season) => {
  return {
    type: ActionTypes.SET_SELECTED_SEASON_TRAINING,
    payload: season,
  };
};

export const setSelectedUnderseason = (underseason) => {
  return {
    type: ActionTypes.SET_SELECTED_UNDERSEASON_TRAINING,
    payload: underseason,
  };
};


export const setSelectedData = (data) => {
  return {
    type: ActionTypes.SET_SELECTED_DATA_TRAINING,
    payload: data
  };
};

export const deleteSelectedData = (data) => {
  return {
    type: ActionTypes.DELETE_SELECTED_DATA_TRAINING,
    payload: data
  };
};