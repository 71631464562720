const BASE_URL = process.env.REACT_APP_BASE_URL

export const getProductSorting = async (token, query) => {

    const response = await fetch(BASE_URL + "/backoffice/product", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(query),
    }).catch((error) => {
        console.log(error);
        return "error";
    });

    if (response.status === 200) {
        const product = await response.json()
        return product;
    } else if (response.status === 401) {
        const countProducts = await response.json()
        return countProducts
    } else {
        return "error";

    }
};

export const getCountProductsToSort = async (token, query) => {

    const response = await fetch(BASE_URL + "/backoffice/products/counter", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(query),
    }).catch((error) => {
        console.log(error);
        return "error";
    });

    if (response.status === 200) {
        const countProducts = await response.json()
        return countProducts;
    } else if (response.status === 401) {
        const countProducts = await response.json()
        return countProducts
    } else {
        return "error";
    }
};


export const updateProductSeason = async (token, query) => {

    const response = await fetch(BASE_URL + "/backoffice/product", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(query),
    }).catch((error) => {
        console.log(error);
        return "error";
    });

    if (response.status === 200) {
        const countProducts = await response.json()
        return countProducts;
    } else {
        return "error";
    }
};

export const deleteProductById = async (token, id) => {

    const data = {
        id: id
    }

    const response = await fetch(BASE_URL + "/backoffice/product", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(data),
    }).catch((error) => {
        console.log(error);
        return "error";
    });

    if (response.status === 200) {
        const result = await response.json()
        return result;
    } else {
        return "error";
    }
};
