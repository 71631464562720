import { ActionTypes } from "../constants/actionsType";

export const setAllTestAi = (allTestAi) => {
  return {
    type: ActionTypes.SET_TEST_AI,
    payload: allTestAi,
  };
};

export const setShowModal = (show) => {
  return {
    type: ActionTypes.SET_SHOW_MODAL,
    payload: show,
  };
};

export const setSelectedSeason = (season) => {
  return {
    type: ActionTypes.SET_SELECTED_SEASON,
    payload: season,
  };
};

export const setSelectedUnderseason = (underseason) => {
  return {
    type: ActionTypes.SET_SELECTED_UNDERSEASON,
    payload: underseason,
  };
};


export const setSelectedTest = (test) => {
  return {
    type: ActionTypes.SET_SELECTED_TEST,
    payload: test
  };
};