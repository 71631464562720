const BASE_URL = process.env.REACT_APP_BASE_URL

export const checkJWT = async (token) => {

    const response = await fetch(BASE_URL+"/auth/me", {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token,
        },
    }).catch((error) => {
        console.log(error);
        return "error";
    });

    if (response.status === 200) {
        const user = await response.json()
        return user;
    } else {
        return "error";
    }
};


export const login = async (email, password) => {
    const data = {
        email: email,
        password: password,
    };

    const response = await fetch(BASE_URL+"/auth/login", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        },
    }).catch((error) => {
        console.log(error);
        return "error";
    });

    if (response.status === 200) {
        const user = await response.json()
        return user;
    } else {
        return "error";

    }


};