import { RefreshIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { getSingleTestAiData } from '../api/testAi/testai';
import ConfirmTestModal from '../components/modal/ConfirmTestModal';
import { setSelectedTest, setShowModal } from '../redux/actions/testAiAction';


import { setSelectedSeason, setSelectedUnderseason } from '../redux/actions/testAiAction';

function SingleTestAi({ match }) {

  // router
  let id = match.params.id;

  //redux
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)

  // state
  const [test, setTest] = useState()
  const [loading, setLoading] = useState(true)


  const getSingleTest = async () => {
    setLoading(true)

    try {
      const data = await getSingleTestAiData(token, id)
      setLoading(false)
      if (data.urlImage[0].filtered.summer != undefined) {
        setTest(data)
        console.log(data.urlImage[0].filtered.summer)
      }else{
        setTest()
      }
      setLoading(false)

    } catch (e) {
      setLoading(false)
      setTest()
      throw "Error"
      
    }
   

  }

  const handleSelect = async (id,season,underseason) => {
    const test = {
      season:season,
      underseason:underseason,
      id:id
    }
    dispatch(setSelectedTest(test))
    dispatch(setSelectedSeason(season))
    dispatch(setSelectedUnderseason(underseason))
    dispatch(setShowModal(true))
  }

  useEffect(() => {
    try {
      getSingleTest()
    } catch (e) {
      console.log(e)
    }
  }, [])

  return (
    <>
      {loading ? <div className='text-center py-16 animate-pulse text-xl flex justify-center items-center'><RefreshIcon className='h-6 mr-2 animate-spin' />Loading...</div> :
      !test ? <h2 className='text-center py-8 font-bold text-2xl'>Nessun test con questo id: {id}</h2> :
        <>
       
          <p className='text-center font-bold text-2xl my-4'>{test.name} {test.surname}</p>
          <p className='text-center font-medium text-xl my-4'>{test.email}</p>
          <div className='flex justify-center'>
          {
            test.report ? <span className='bg-green-600 text-white rounded-full py-1 px-8 text-xs'>Report</span> : <></>
          }
          </div>
          <h3 className='text-center font-bold text-2xl my-4'>Immagini originali</h3>
          <section className='bg-gray-50 p-2'>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-8 mx-16'>
              {
                test.urlImage.map((i) => {
                  return <img className='rounded-lg w-96' src={i.base} />
                })
              }

            </div>


            <div className=''>
              {
                test.urlImage.map((image, i) => {
                  console.log(image)
                  return (
                    <>
                      <p className='text-center text-3xl font-bold my-8'>Immagine originale {i + 1}</p>

                      {
                        Object.keys(image.filtered).map((keyName, i) => {

                          return (<>
                            <p className='my-4 text-center text-xl font-bold uppercase'>{keyName}</p>
                            <div className='grid grid-cols-2 md:grid-cols-4 gap-2'>
                              {
                                image.filtered[keyName].map((s) => {

                                  return (
                                    <div className='flex flex-col items-center justify-center bg-gray-50 border rounded-lg'>
                                      <img src={s.url} className="w-72 p-4" />
                                      <p className='uppercase'>{s.underseason}</p>
                                      <button onClick={()=>handleSelect(test._id,keyName,s.underseason)} className='bg-black bg-opacity-80 hover:bg-opacity-100 text-white px-8 py-2 mb-2 rounded-lg'>Conferma</button>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </>)

                        }
                        )}

                    </>)

                })
              }
            </div>
          </section>

          
          
        </>

      }
    </>
  )
}

export default SingleTestAi