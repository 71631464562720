import React, { useEffect, useState } from 'react'
import { getTrainingAiData, getTrainingAiStat } from '../api/trainingAi/trainingai'
import { setAllTrainingAiData } from '../redux/actions/trainingAiAction'
import { useDispatch, useSelector } from 'react-redux'
import TrainingAiList from '../components/list/TrainingAiList'
import { RefreshIcon } from '@heroicons/react/solid'

function TrainingAi() {

    // state
    const [allTrainingData, setAllTrainingAiDataState] = useState()
    const [stat, setStat] = useState()


    //redux
    const dispatch = useDispatch()
    const token = useSelector(state => state.user.token)
    const data = useSelector(state => state.trainingAi.allTrainingData)

    const getTrainingData = async () => {
        setAllTrainingAiDataState()
        try {
            const data = await getTrainingAiData(token)
            setAllTrainingAiDataState(data)
            dispatch(setAllTrainingAiData(data))
   
        } catch (e) {
            // eslint-disable-next-line
            throw "Error"
        }

    }

    const getTrainingStat = async () => {
        try {
            const stat = await getTrainingAiStat(token)
            setStat(stat)
        } catch (e) {
            // eslint-disable-next-line
            throw "Error"
        }
    }

    useEffect(() => {
        try {
            getTrainingData()
            getTrainingStat()
        } catch (e) {
            console.log(e)
        }
    }, [])


    return (
        <div>

            {
                data.length == 0 ? <div className='text-center py-16 animate-pulse text-xl flex justify-center items-center'><RefreshIcon className='h-6 mr-2 animate-spin' />Loading...</div> :

                    <section className='mx-auto max-w-6xl'>
                        {
                            !stat ? <></> :
                                <div className='grid grid-cols-3 gap-4 mt-4 mx-4 md:mx-4'>
                                    <div className='flex items-center p-8 shadow-xl rounded-lg'>
                                        Tot. {stat.countTestAiAll}
                                    </div>
                                    <div className='flex bg-red-400 text-white items-center p-8 shadow-xl rounded-lg'>

                                        Da fare: {stat.countTestAiNotAnswered}
                                    </div>

                                    <div className='flex bg-green-500 text-white items-center p-8 shadow-xl rounded-lg'>

                                        Fatti: {stat.countTestAiAnswered}
                                    </div>
                                </div>
                        }
                        <div className='flex justify-end mx-4 md:mx-4 mt-4'>
                            <button onClick={() => {getTrainingData(); getTrainingStat()}} className='bg-gray-50 p-4 flex rounded-lg'><RefreshIcon className='h-6 pr-2' />Refresh</button>
                        </div>
                        <div className='mx-4 md:mx-4 py-4 flex flex-col space-y-4'>
                            {
                                data.map((data) => {

                                    return (
                                        <TrainingAiList key={data._id} id={data._id} name={data.name} time={data.createdAt} url={data.urlImage[0]} />
                                    )
                                })
                            }
                        </div>
                    </section>
            }
        </div>
    )
}

export default TrainingAi