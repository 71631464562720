// router
import PrivateRoute from "./components/router/PrivateRoute";
import {
  Switch,
  Route
} from "react-router-dom";

// redux
import { useSelector } from 'react-redux';

// pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import SortingDress from "./pages/SortingDress";
import SortingDressBrand from "./pages/SortingDressBrand";

//ui
import Navbar from "./components/navbar/Navbar";
import VoucherInfo from "./pages/VoucherInfo";
import TestAi from "./pages/TestAi";
import SingleTestAi from "./pages/SingleTestAi";
import ConfirmTestModal from "./components/modal/ConfirmTestModal";
import TrainingAi from "./pages/TrainingAi";
import ConfirmTrainingModal from "./components/modal/ConfirmTrainingModal";
import SingleTrainingAi from "./pages/SingleTrainingAi";


function App() {

  const isAuthenticated = useSelector(state => state.user.loggin)

  return (
    <>
      {
        isAuthenticated ? (<>

          <ConfirmTestModal />
          <ConfirmTrainingModal />

          <Navbar />
        </>

        ) : (<></>)
      }
      <Switch>
        <Route path="/login" component={Login} exact={true} />
        <PrivateRoute isAuthenticated={isAuthenticated} path="/" component={Home} exact={true} />
        <PrivateRoute isAuthenticated={isAuthenticated} path="/sortingall" component={SortingDress} exact={true} />
        <PrivateRoute isAuthenticated={isAuthenticated} path="/sortingbrand" component={SortingDressBrand} exact={true} />
        <PrivateRoute isAuthenticated={isAuthenticated} path="/voucher" component={VoucherInfo} exact={true} />
        <PrivateRoute isAuthenticated={isAuthenticated} path="/testai" component={TestAi} exact={true} />
        <PrivateRoute isAuthenticated={isAuthenticated} path="/trainingai" component={TrainingAi} exact={true} />
        <PrivateRoute isAuthenticated={isAuthenticated} path="/testai/:id" component={SingleTestAi} exact={true} />
        <PrivateRoute isAuthenticated={isAuthenticated} path="/trainingai/:id" component={SingleTrainingAi} exact={true} />

      </Switch>
    </>
  );
}

export default App;
