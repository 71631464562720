import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//api
import { getCountProductsToSort, getProductSorting, updateProductSeason, deleteProductById } from "../../api/product/product"
import { getBrands } from '../../api/product/brand';

// ui
import { Notify } from 'notiflix';
import Cookies from 'js-cookie';

//router
import { setLogging } from '../../redux/actions/userAction';

// constanti
const stagioni = ["Summer", "Spring", "Autumn", "Winter"];
const bodyshapeArray = ["Clessidra", "Otto", "Triangolo", "Inverso", "Rettangolo", "Diamante"]

function ProductCardBrand() {

  //redux
  const token = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  //state
  const [brand, setBrand] = useState()
  const [product, setProduct] = useState();

  const [season, setSeason] = useState();
  const [productRandom, setProductRandom] = useState();
  const [bodyshapeArrayOfDress, setBodyshapeArrayOfDress] = useState()

  const [brandArr, setBrandArr] = useState([])
  const [countProductToSort, setCountProductToSort] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      const data = await getBrands()
      setBrandArr(data)
    }
    fetch();
  }, [])

  // function
  const resetFilter = () => {
    setProductRandom(false)
    setSeason()
    setBodyshapeArrayOfDress([])
    setBrand("");
    setProduct()
    setCountProductToSort(0)
  }

  const removeBodyshape = (data) => {
    let arr = bodyshapeArrayOfDress;
    const index = arr.indexOf(data);
    console.log(index)
    if (index > -1) {
      arr.splice(index, 1);
      setBodyshapeArrayOfDress([...arr])
    }
  }

  const addBodyshape = (data) => {
    let arr = bodyshapeArrayOfDress;
    bodyshapeArrayOfDress.push(data);
    setBodyshapeArrayOfDress([...arr])

  }

  // update product to backend
  const updateProduct = async (id) => {

    let query;

    if(bodyshapeArrayOfDress.length === 0){
      query = {
        _id: id,
        season: season
      }
    }else{
      query = {
        _id: id,
        bodyshape: bodyshapeArrayOfDress,
        season: season,
      }
    }

    

    const response = await updateProductSeason(token, query);

    if (response.message == "updated_product") {
      Notify.success("Prodotto modificato")
      getNewRandomProduct()
    } else {
      Notify.failure("Qualcosa è andato storto")
    }
  }

  // delete product to backenbd
  const deleteData = async (id) => {

    const response = await deleteProductById(token, id);

    if (response.message == "deleted_product") {
      getNewRandomProduct();
      Notify.success("Prodotto eliminato")
    } else {
      Notify.failure("Errore")
    }

    console.log(id);
  };

  // get new product from backend
  const getNewRandomProduct = async () => {

    //costruzione query
    let query = {}

    if (brand == "all") {
      query = {
        query: {
          sortingBodyshape: false,
          sortingSeason: false
        }
      }
    } else {
      query = {
        query: {
          brand: brand,
          sortingBodyshape: false,
          sortingSeason: false
        }
      }
    }

    let numberProductToSorting = await getCountProductsToSort(token, query);

    if (numberProductToSorting.message == "not_authorized") {
      Notify.failure("Non sei autorizzato")
      Cookies.remove("khooa-backoffice")
      dispatch(setLogging(false))
    } else {
      if (numberProductToSorting.countProducts != 0) {
        setCountProductToSort(numberProductToSorting.countProducts);
        const payload = await getProductSorting(token, query);

        if (payload.message == "products_not_found") {
          setProductRandom(false)
          Notify.info("Non ci sono prodotti per i filtri selezionati")
        } else if (payload.message == "not_authorized") {
          Notify.failure("Non sei autorizzato")
          Cookies.remove("khooa-backoffice")
          dispatch(setLogging(false))

        } else if (payload == "error") {
          setProductRandom(false);
          Notify.failure("Errore")
        } else {
          setSeason(payload.season);
          //setBodyshape(payload.bodyshape)
          setBodyshapeArrayOfDress(payload.bodyshape)
          setProduct(payload)
          setProductRandom(true);
        }
      } else {
        Notify.info("Non ci sono prodotti per i filtri selezionati")
        setCountProductToSort(0);
        setProductRandom(false);
      }
    }
  }

  return (
    <div className="flex justify-center flex-col items-center bg-gray-100 p-4 rounded-lg">
      <label className="text-center font-semibold text-2xl">Filtraggio</label>
      <div className="flex flex-col justify-center items-start space-y-2 mt-2">
        <div className="flex flex-col">
          <label className="text-xl mr-2">Brand:</label>
          <select onChange={(e) => setBrand(e.target.value)}>
            <option selected disabled>Seleziona un brand</option>
            {
              brandArr.map((brand) => {
                return <option value={brand.value}>{brand.name}</option>
              })
            }
          </select>
        </div>
        <div>
          {
            brand ? (
              
                <div className="space-y-2">
                  <button onClick={() => getNewRandomProduct()} className="text-white hover:shadow-lg  bg-black px-4 py-3 rounded-xl min-w-full">Cerca prodotti</button>
                  <button onClick={() => resetFilter()} className="text-black hover:shadow-lg  bg-white border-black px-4 py-3 rounded-xl min-w-full">Resetta filtri</button>

                </div>
              ): (
              <label className="text-red-500">Seleziona un brand</label>
            )

          }
        </div>
      </div>
      {
        // non ci sono prodotti da smistare
        !productRandom ? (
          <div className="mt-4">
            <label className="text-center">Nessun prodotto da smistare</label>
          </div>
        ) : (
          // ci sono prodotti da smistare
          <div className="p-5">
            <label className="text-md text-right">
              Prodotti ancora da smistare per il brand {brand}: {countProductToSort}
            </label>
            <div className="grid grid-cols-1 md:grid-cols-2 bg-white shadow-sm rounded-xl p-2 border-2 border-black">
              <div className="flex justify-center">
                <img src={product.imgUrl} className="rounded-xl w-full h-full p-3" />
              </div>
              <div className="flex flex-col space-y-2">
                <label className="text-xl">Brand: {product.brand}</label>
                <label className="text-xl">Categoria: {product.category}</label>
                <label className="text-3xl font-bold">
                  {product.name}
                </label>

                {/* SEASON BUTTON */}
                <div className="flex flex-col border-t-2 border-black space-y-2 mb-2 mt-2">
                  <label className="text-xl mt-2">Stagioni</label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-row space-x-2 mt-2 ">
                      <div className="grid grid-cols-2 md:grid-cols-4 ">
                        {stagioni.map((stagione) =>
                          stagione == season ? (
                            <button
                              key={stagione}
                              onClick={() => setSeason(stagione)}
                              className="m-1 text-white bg-black px-4 py-3 rounded-xl min-w-[100px]"
                            >
                              {stagione}
                            </button>
                          ) : (
                            <div>
                              <button
                                key={stagione}

                                onClick={() => setSeason(stagione)}
                                className="m-1 text-black bg-white border-2 border-black px-4 py-3 rounded-xl min-w-[100px]"
                              >
                                {stagione}
                              </button>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* BODYSHAPE BUTTON */}
               {/* <div className="flex flex-col border-t-2 border-black space-y-2 mb-2 mt-2">
                  <label className="text-xl mt-2">Bodyshape</label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-row space-x-2 mt-2 ">
                      <div className="grid grid-cols-2 md:grid-cols-4 ">
                        bodyshapeArray.map((singleBodyshape) =>
                          singleBodyshape == bodyshape ? (
                            <button
                              
                              onClick={() => setBodyshape(singleBodyshape)}
                              className="m-1 text-white border-2 bg-black px-4 py-3 rounded-xl min-w-[100px]"
                            >
                              {singleBodyshape}
                            </button>
                          ) : (
                            <div>
                              <button
                                onClick={() => setBodyshape(singleBodyshape)}
                                className="m-1 text-black bg-white border-2 border-black px-4 py-3 rounded-xl min-w-[100px]"
                              >
                                {singleBodyshape}
                              </button>
                            </div>
                          )
                          )
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* BODYSHAPE BUTTON NEW */}
                <div className="flex flex-col border-t-2 border-black space-y-2 mb-2 mt-2">
                  <label className="text-xl mt-2">Bodyshape</label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-row space-x-2 mt-2 ">
                      <div className="grid grid-cols-2 md:grid-cols-4 ">
                        {bodyshapeArray.map((singleBodyshape) =>
                          bodyshapeArrayOfDress.includes(singleBodyshape) ? (
                            <button
                              key={singleBodyshape}
                              onClick={() => removeBodyshape(singleBodyshape)}
                              className="m-1 text-white border-2 bg-black px-4 py-3 rounded-xl min-w-[100px]"
                            >
                              {singleBodyshape}
                            </button>
                          ) : (
                            <div>
                              <button
                                key={singleBodyshape}
                                onClick={() => addBodyshape(singleBodyshape)}
                                className="m-1 text-black bg-white border-2 border-black px-4 py-3 rounded-xl min-w-[100px]"
                              >
                                {singleBodyshape}
                              </button>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* SEND/DELETE BUTTON */}
                <div className="flex flex-col border-t-2 border-black space-y-2 mb-2 mt-2">
                  <div className="flex flex-col mt-2">
                    <div className="flex flex-col space-y-2">
                      <button
                        onClick={() => updateProduct(product._id)}
                        className="text-white hover:shadow-lg  bg-black px-4 py-3 rounded-xl min-w-full"
                      >
                        🐨 Invia 🐨
                      </button>

                      <button
                        onClick={() => deleteData(product._id)}
                        className="text-white hover:shadow-lg bg-red-500 px-4 py-3 rounded-xl min-w-full"
                      >
                        🗑 Elimina 🗑
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default ProductCardBrand
