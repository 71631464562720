import { combineReducers } from "redux";
import { testAiReducer } from "./testAiReducer";
import { trainingAiReducer } from "./trainingAiReducer";
import { userReducer } from "./userReducer";

const reducers = combineReducers({
  user: userReducer,
  testAi: testAiReducer,
  trainingAi: trainingAiReducer
});
export default reducers;