import Cookies from "js-cookie"
import React, { useState } from "react"

import { login } from "../../api/auth/auth"

import { Notify } from "notiflix"

import {setUser, setLogging, setToken} from "../../redux/actions/userAction"
import { useDispatch } from "react-redux"

import { useHistory } from "react-router"


function LoginForm() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const dispatch = useDispatch()
    const history = useHistory()

    const handleLogin = async (e) => {
        
        e.preventDefault();

        const response = await login(email, password)

        if (response == "error") {
            Notify.failure("Errore nella login!")
        } else if (response.message == "logged_in") {
            /* SET COOKIE E REDIRECT TO HOME */
            Cookies.set('khooa-backoffice', response.user.jwt)

    
            dispatch(setUser(response.user))
            dispatch(setToken(response.user.jwt))
            dispatch(setLogging(true))

            Notify.success("Ciao " + response.user.nickname + "✨")
            history.push("/")

        } else {
            /* NON AUTORIZZATO */
            Notify.failure("Non sei autorizzato ad entrare!")
        }

    }
    

    return (
        <div>
            <form onSubmit={handleLogin} className="flex flex-col justify-center align-middle mt-4">

                <label className="text-left">
                    Email
                </label>
                <input required className="p-3 bg-gray-100 rounded-xl m-1" name="email" type="email" onChange={e => setEmail(e.target.value)} />

                <label className="text-left">
                    Password
                </label>
                <input required className="p-3 bg-gray-100 rounded-xl m-1 mb-6" type="password" name="password" onChange={e => setPassword(e.target.value)} />


                <button type="submit" className="bg-black  min-w-[200px] border-2 border-black text-white rounded-xl px-5 py-3 active:scale-95 duration-500 transform hover:shadow-xl hover:scale-[1.02]">
                    { /*
                        loading ? (
                            <ClipLoader size={25} color="#FFFFFF" loading={true} className="mr-2"/>
                        ) : (
                            <></>
                        ) */
                    }
                    💼 Login su Khooa Backoffice 💼
                </button>


            </form>
            { /* <label className="mt-6 text-red-500 text-xl">{errorMessageLogin}</label>  */}

        </div>
    )
}

export default LoginForm
