const BASE_URL = process.env.REACT_APP_BASE_URL

export const getVoucherInfo = async (token,code) => {

    const response = await fetch(BASE_URL + "/backoffice/coupon?all=false&code="+code, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
    }).catch((error) => {
        console.log(error);
        return "error";
    });

    if (response.status === 200) {
        const data = await response.json()
        return data;
    } else {
        return "error";
    }
};

export const getAllVoucherInfo = async (token) => {

    const response = await fetch(BASE_URL + "/backoffice/coupon?all=true", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
    }).catch((error) => {
        console.log(error);
        return "error";
    });

    if (response.status === 200) {
        const data = await response.json()
        console.log(data)
        return data;
    } else {
        return "error";
    }
};

export const getCountVoucher = async () => {

    const response = await fetch(BASE_URL + "/payment/availability", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).catch((error) => {
        console.log(error);
        return "error";
    });

    if (response.status === 200) {
        const data = await response.json()
        return data;
    } else {
        return "error";
    }
};