export const ActionTypes = {
    SET_USER: "SET_USER",
    SET_LOGGING: "SET_LOGGING",
    SET_TOKEN: "SET_TOKEN",
    SET_TEST_AI: "SET_TEST_AI",
    SET_TRAINING_AI_DATA: "SET_TRAINING_AI_DATA",
    SET_SHOW_MODAL: "SET_SHOW_MODAL",
    SET_SELECTED_SEASON:"SET_SELECTED_SEASON",
    SET_SELECTED_UNDERSEASON:"SET_SELECTED_UNDERSEASON",
    SET_SELECTED_TEST:"SET_SELECTED_TEST",
    SET_SHOW_MODAL_TRAINING: "SET_SHOW_MODAL_TRAINING",
    SET_SELECTED_SEASON_TRAINING:"SET_SELECTED_SEASON_TRAINING",
    SET_SELECTED_UNDERSEASON_TRAINING:"SET_SELECTED_UNDERSEASON_TRAINING",
    SET_SELECTED_DATA_TRAINING:"SET_SELECTED_TEST_TRAINING",
    DELETE_SELECTED_DATA_TRAINING:"DELETE_SELECTED_TEST_TRAINING"

  };