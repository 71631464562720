import { RefreshIcon, TrashIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { Notify } from 'notiflix';
import { getSingleTrainingAiData, deleteTrainingDataById } from '../api/trainingAi/trainingai';
import { setShowModal } from '../redux/actions/trainingAiAction';
import { setSelectedSeason, setSelectedUnderseason, setSelectedData } from '../redux/actions/trainingAiAction';

const ALL_SEASON = [
    {
        season: "autumn",
        underseason: "absolute"
    },
    {
        season: "autumn",
        underseason: "deep"
    },
    {
        season: "autumn",
        underseason: "soft"
    },
    {
        season: "autumn",
        underseason: "warm"
    },

    {
        season: "spring",
        underseason: "absolute"
    },
    {
        season: "spring",
        underseason: "light"
    },
    {
        season: "spring",
        underseason: "warm"
    },
    {
        season: "spring",
        underseason: "bright"
    },

    {
        season: "winter",
        underseason: "absolute"
    },
    {
        season: "winter",
        underseason: "bright"
    },
    {
        season: "winter",
        underseason: "cool"
    },
    {
        season: "winter",
        underseason: "deep"
    },

    {
        season: "summer",
        underseason: "absolute"
    },
    {
        season: "summer",
        underseason: "cool"
    },
    {
        season: "summer",
        underseason: "light"
    },
    {
        season: "summer",
        underseason: "soft"
    },
]

function SingleTrainingAi({ match }) {

    // router
    let id = match.params.id;
    const history = useHistory()

    //redux
    const dispatch = useDispatch()
    const token = useSelector(state => state.user.token)

    // state
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)


    const getSingleData = async () => {
        setLoading(true)

        try {
            const data = await getSingleTrainingAiData(token, id)
           
            setLoading(false)
            if (data) {
                setData(data)
            } else {
                setData()
            }
            setLoading(false)

        } catch (e) {
            setLoading(false)
            setData()
            throw "Error"

        }


    }

    const handleSelect = async (id, season, underseason) => {
        const data = {
            season: season,
            underseason: underseason,
            id: id
        }
        dispatch(setSelectedData(data))
        dispatch(setSelectedSeason(season))
        dispatch(setSelectedUnderseason(underseason))
        dispatch(setShowModal(true))
    }

    const deleteData = async () => {
        let response = deleteTrainingDataById(token,id);

        if (response){
            Notify.success("Eliminato con successo ✨")
            history.push("/trainingai")
            
        } else {
            Notify.error("Errore nell'eliminazione 📛")
        }

    }

    useEffect(() => {
        try {
            getSingleData()
        } catch (e) {
            console.log(e)
        }
    }, [])

    return (
        <>
            {loading ? <div className='text-center py-16 animate-pulse text-xl flex justify-center items-center'><RefreshIcon className='h-6 mr-2 animate-spin' />Loading...</div> :
                !data ? <h2 className='text-center py-8 font-bold text-2xl'>Nessun test con questo id: {id}</h2> :
                    <>
                        <p className='text-center font-bold text-2xl my-4'>{data.name}</p>
                        <div className='flex justify-center mb-4'>
                            <div onClick={() => deleteData()} className='bg-red-400 px-8 py-2 rounded-md w-40 text-center flex justify-center cursor-pointer hover:bg-red-300'>
                                <p className='text-red-700 flex items-center'>Elimina <TrashIcon height={30} /></p>
                            </div>
                        </div>
                        <section className='bg-gray-50 p-2 flex justify-center'>
                            <div className='grid grid-cols-2 md:grid-cols-4'>
                                {
                                    data.urlImage.map((image, i) => {
                                        return (
                                            <div key={i} className='flex flex-col space-x-2'>
                                                <img src={image} className="w-72 p-4" />
                                                {
                                                    i > 13 ?
                                                    <>
                                                        <p className='text-center'>{ALL_SEASON[i-14].season + " - " + ALL_SEASON[i-14].underseason}</p>
                                                         <button onClick={()=>handleSelect(id,ALL_SEASON[i-14].season,ALL_SEASON[i-14].underseason)} className='bg-black bg-opacity-80 hover:bg-opacity-100 text-white px-8 py-2 mb-2 rounded-lg'>Conferma</button>
                                                    </>
                                                    : <></>
                                                }
                                            </div>)
                                    })
                                }
                            </div>
                        </section>
                    </>
            }
        </>
    )
}

export default SingleTrainingAi