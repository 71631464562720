import { Notify } from 'notiflix'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getVoucherInfo, getAllVoucherInfo } from '../api/consulting/voucher'

function VoucherInfo() {

    const [voucher, setVoucher] = useState("")
    const [data, setData] = useState([])
    //const [allData, setAllData] = useState([])


    //redux
    const token = useSelector(state => state.user.token)

    const getInfoVoucher = async (e) => {
        e.preventDefault();
        let response = await getVoucherInfo(token, voucher)

        if (response === "error") {
            console.log(response)
            Notify.failure("Codice non valido")
        } else {
            if (response.length === 0) {
                Notify.failure("Codice non valido")
                setData([])

            } else {
                console.log(response)
                setData(response)
            }

        }
    }

    const getAllInfoVoucher = async () => {

        let response = await getAllVoucherInfo(token, voucher)

        if (response === "error") {
            console.log(response)
            Notify.failure("Errore")
        } else {
            if (response.length === 0) {
                Notify.failure("Non ci sono voucher")
                setData([])

            } else {
                console.log(response)
                setData(response)
            }

        }
    }


    return (
        <div className='flex justify-center mt-8 flex-col items-center mx-4 md:mx-0'>
            <h2 className='text-2xl font-semibold text-center'>Recupera tutti i voucher</h2>
            <button onClick={() => getAllInfoVoucher()} className='mb-8 hover:shadow-lg m-2 p-4 rounded-lg bg-blue-400 text-white' type='submit'>Recupera</button>

            <h2 className='text-2xl font-semibold text-center'>Informazioni singolo voucher</h2>
            <form onSubmit={(e) => getInfoVoucher(e)} className='flex flex-col bg-gray-100 shadow-lg p-4 rounded-lg mb-8'>
                <label className='text-center' htmlFor="voucher">Inserisci voucher</label>
                <input value={voucher} onChange={(e) => setVoucher(e.target.value)} className='border-2 border-gray-100' id='voucher' type="text" />
                <button className='hover:shadow-lg m-2 p-4 rounded-lg bg-blue-400 text-white' type='submit'>Ricerca</button>
            </form>
            <div>
                {
                    data.length === 0 ? (<div className='px-4 text-center mt-8'>Inserisci un voucher per recuperare le informazioni</div>) : (
                        data.length === 1 ? (
                            <div className='mb-16'>
                                <h2 className='text-2xl font-semibold mt-8 text-center'>Informazioni per il voucher {voucher}</h2>
                                <div className='flex flex-col items-center justify-center'>
                                    {
                                        data[0].isUsed ? (<div className="bg-red-500 text-white p-2 rounded-lg w-full text-center my-2">
                                            <p>CODICE GIA' USATO</p>
                                        </div>) : (
                                            <div className="bg-green-500 text-white p-2 rounded-lg w-full text-center my-2">
                                                <p>CODICE NON ANCORA USATO</p>
                                            </div>
                                        )
                                    }
                                    <h3 className='text-xl font-semibold mt-8 text-center'>Dati di fatturazione</h3>
                                    <div className="bg-gray-100 p-2 rounded-lg w-full text-center my-2">
                                        <p>Nome</p>
                                        <p className='bg-white p-1 rounded-lg'>{data[0].nameBuyer}</p>
                                    </div>

                                    <div className="bg-gray-100 p-2 rounded-lg w-full text-center my-2">
                                        <p>Cognome</p>
                                        <p className='bg-white p-1 rounded-lg'>{data[0].surnameBuyer}</p>
                                    </div>
                                    <div className="bg-gray-100 p-2 rounded-lg w-full text-center my-2">
                                        <p>Data di nascita</p>
                                        <p className='bg-white p-1 rounded-lg'>{(data[0].dateOfBirth)}</p>
                                    </div>

                                    <div className="bg-gray-100 p-2 rounded-lg w-full text-center my-2">
                                        <p>Codice fiscale</p>
                                        <p className='bg-white p-1 rounded-lg'>{data[0].fiscalCode}</p>
                                    </div>
                                    <div className="bg-gray-100 p-2 rounded-lg w-full text-center my-2">
                                        <p>Indirizzo</p>
                                        <p className='bg-white p-1 rounded-lg'>{data[0].billingAddress}</p>
                                    </div>

                                    <div className="bg-gray-100 p-2 rounded-lg w-full text-center my-2">
                                        <p>Email</p>
                                        <p className='bg-white p-1 rounded-lg'>{data[0].customerEmail}</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='mb-4 w-full'>
                                {
                                    data.map((item) => {
                                        console.log(item)
                                        return <div className='mb-8 bg-gray-100 shadow-md px-8 py-4 rounded-xl w-full'>
                                            <div className='flex flex-col items-center justify-center'>
                                                {
                                                    item.isUsed ? (<div className="bg-red-500 text-white p-2 rounded-lg w-full text-center my-2">
                                                        <p>CODICE GIA' USATO</p>
                                                    </div>) : (
                                                        <div className="bg-green-500 text-white p-2 rounded-lg w-full text-center my-2">
                                                            <p>CODICE NON ANCORA USATO</p>
                                                        </div>
                                                    )
                                                }
                                                <h3 className='text-xl font-semibold mt-8 text-center'>Dati di fatturazione</h3>
                                                <div className="bg-gray-100 p-2 rounded-lg w-full text-center my-2">
                                                    <p>Nome</p>
                                                    <p className='bg-white p-1 rounded-lg'>{item.nameBuyer}</p>
                                                </div>

                                                <div className="bg-gray-100 p-2 rounded-lg w-full text-center my-2">
                                                    <p>Cognome</p>
                                                    <p className='bg-white p-1 rounded-lg'>{item.surnameBuyer}</p>
                                                </div>
                                                <div className="bg-gray-100 p-2 rounded-lg w-full text-center my-2">
                                                    <p>Data di nascita</p>
                                                    <p className='bg-white p-1 rounded-lg'>{(item.dateOfBirth)}</p>
                                                </div>

                                                <div className="bg-gray-100 p-2 rounded-lg w-full text-center my-2">
                                                    <p>Codice fiscale</p>
                                                    <p className='bg-white p-1 rounded-lg'>{item.fiscalCode}</p>
                                                </div>
                                                <div className="bg-gray-100 p-2 rounded-lg w-full text-center my-2">
                                                    <p>Indirizzo</p>
                                                    <p className='bg-white p-1 rounded-lg'>{item.billingAddress}</p>
                                                </div>

                                                <div className="bg-gray-100 p-2 rounded-lg w-full text-center my-2">
                                                    <p>Email</p>
                                                    <p className='bg-white p-1 rounded-lg'>{item.customerEmail}</p>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }


                            </div>
                        )
                    )
                }
            </div>


        </div>
    )
}

export default VoucherInfo
