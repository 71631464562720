import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { putSingleTrainingAi } from '../../api/trainingAi/trainingai'
import { setShowModal } from '../../redux/actions/trainingAiAction'
import { useHistory } from "react-router"
import { Notify } from "notiflix"


function ConfirmTrainingModal() {

    //state
    const [loading, setLoading] = useState(false)

    //router
    const history = useHistory()

    //redux
    const dispatch = useDispatch()
    const show = useSelector(state => state.trainingAi.showModalTraining)
    const data = useSelector(state => state.trainingAi.data)
    const token = useSelector(state => state.user.token)

    const sendResult = async() => {
        setLoading(true)
        try {
            
            const result = await putSingleTrainingAi(token, data.id,data.season,data.underseason)
            setLoading(false)
            Notify.success("Invio corretto")
            dispatch(setShowModal(false))
            history.push("/trainingai")
      
          } catch (e) {
            setLoading(false)
            Notify.failure("Errore nell'invio")

            throw "Error"
          }
    }


    return (
        <div>
            {
                !show ? <></> :
                    <div className='min-h-screen bg-opacity-50 bg-black flex justify-center items-center text-white w-full fixed z-10'>
                        <div className='bg-white p-12 text-black rounded-lg flex flex-col  justify-center'>
                            <div onClick={()=>dispatch(setShowModal(false))} className='text-red-500 cursor-pointer flex justify-end mb-8'>
                                Chiudi
                            </div>
                            <p>Stagione: {data.season}</p>
                            <p className='mb-8'>Sotto stagione: {data.underseason}</p>
                            <button onClick={()=>sendResult()} className='bg-black rounded-lg py-2 px-8 text-white'>{loading ? "Invio in corso" : "Invia"}</button>

                        </div>
                    </div>
            }
        </div>
    )
}

export default ConfirmTrainingModal