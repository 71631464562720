import { ActionTypes } from '../constants/actionsType'

const intialState = {
    allTest: [],
    showModal: false,
    selectedSeason: "",
    selectedUnderseason: "",
    test: {}
};

export const testAiReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_TEST_AI:
            return { ...state, allTest: payload };
        case ActionTypes.SET_SHOW_MODAL:
            return { ...state, showModal: payload };
        case ActionTypes.SET_SELECTED_SEASON:
            return { ...state, selectedSeason: payload };
        case ActionTypes.SET_SELECTED_UNDERSEASON:
            return { ...state, selectedUnderseason: payload };
        case ActionTypes.SET_SELECTED_TEST:
            return { ...state, test: payload }
        default:

            return state;
    }
};