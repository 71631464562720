import React from 'react'

/* UI */
import GridBackground from '../components/background/GridBackground';
import LoginForm from '../components/forms/LoginForm';

function Login() {
    return (
        <div className="relative h-screen flex items-center justify-center bg-gray-900">
          <GridBackground />
  
          {/* content */}
          <div className="relative ">
            <h1 className="text-5xl text-center text-white font-extrabold">
              Khooa <span className="text-purple-500">Backoffice</span>
            </h1>
            <div className="bg-white shadow-lg rounded-3xl p-5 m-2 max-w-[700px]">
              <LoginForm />
            </div>
          </div>
        </div>
      );
}

export default Login