import { ActionTypes } from '../constants/actionsType'

const intialState = {
    allTrainingData: [],
    showModalTraining: false,
    selectedSeasonTraining: "",
    selectedUnderseasonTraining: "",
    data: {}
};

export const trainingAiReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_TRAINING_AI_DATA:
            return { ...state, allTrainingData: payload };
        case ActionTypes.SET_SHOW_MODAL_TRAINING:
            return { ...state, showModalTraining: payload };
        case ActionTypes.SET_SELECTED_SEASON_TRAINING:
            return { ...state, selectedSeasonTraining: payload };
        case ActionTypes.SET_SELECTED_UNDERSEASON_TRAINING:
            return { ...state, selectedUnderseasonTraining: payload };
        case ActionTypes.SET_SELECTED_DATA_TRAINING:
            return { ...state, data: payload };
        case ActionTypes.DELETE_SELECTED_DATA_TRAINING:
            return { ...state, allTrainingData: state.allTrainingData.filter((d) => d._id !== payload) };
        default:

            return state;
    }
};