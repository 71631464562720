/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'

import logo from "../../img/logo.png"
import { NavLink } from 'react-router-dom'

import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Navbar() {

    const user = useSelector(state => state.user.user)

    const history = useHistory()

    const signOut = () => {
        console.log("SIGN OUT")
        Cookies.remove("khooa-backoffice")
        history.go(0)
    }

    return (
        <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex-shrink-0 flex items-center">
                                    <img
                                        className="block lg:hidden h-8 w-auto"
                                        src={logo}
                                        alt="Khooa"
                                    />
                                    <img
                                        className="hidden lg:block h-8 w-auto"
                                        src={logo}
                                        alt="Khooa"
                                    />
                                </div>
                                <div className="hidden sm:block sm:ml-6">
                                    <div className="flex space-x-4">
                                        <NavLink style={({ isActive }) =>
                                            isActive ? "bg-gray-700" : undefined
                                        } className="text-white rounded-lg p-2 hover:bg-gray-700" to="/">Home</NavLink>
                                        <NavLink style={({ isActive }) =>
                                            isActive ? "bg-gray-700" : undefined} className="text-white rounded-lg p-2 hover:bg-gray-700" to="/sortingall">Smistamento completo</NavLink>
                                        <NavLink style={({ isActive }) =>
                                            isActive ? "bg-gray-700" : undefined} className="text-white rounded-lg p-2 hover:bg-gray-700" to="/sortingbrand">Smistamento per brand</NavLink>
                                        <NavLink style={({ isActive }) =>
                                            isActive ? "bg-gray-700" : undefined} className="text-white rounded-lg p-2 hover:bg-gray-700" to="/voucher">Voucher</NavLink>
                                        <NavLink style={({ isActive }) =>
                                            isActive ? "bg-gray-700" : undefined} className="text-white rounded-lg p-2 hover:bg-gray-700" to="/testai">Test Ai</NavLink>
                                        <NavLink style={({ isActive }) =>
                                            isActive ? "bg-gray-700" : undefined} className="text-white rounded-lg p-2 hover:bg-gray-700" to="/trainingai">Training Ai</NavLink>



                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                <button
                                    type="button"
                                    className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                >


                                </button>

                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-3 relative">
                                    <div>
                                        <Menu.Button className="bg-gray-700 flex text-white p-2 text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                            <span className="sr-only">Open user menu</span>
                                            <label>Ciao, {user.name}</label>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button

                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                        onClick={() => signOut()}
                                                    >
                                                        Logout
                                                    </button>
                                                )}
                                            </Menu.Item>


                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="flex flex-col px-2 pt-2 pb-3 space-y-1">
                            <NavLink style={({ isActive }) =>
                                isActive ? "bg-gray-700" : undefined
                            } className="text-white rounded-lg p-2 hover:bg-gray-700" to="/">Home</NavLink>
                            <NavLink style={({ isActive }) =>
                                isActive ? "bg-gray-700" : undefined} className="text-white rounded-lg p-2 hover:bg-gray-700" to="/sortingall">Smistamento completo</NavLink>
                            <NavLink style={({ isActive }) =>
                                isActive ? "bg-gray-700" : undefined} className="text-white rounded-lg p-2 hover:bg-gray-700" to="/sortingbrand">Smistamento per brand</NavLink>
                            <NavLink style={({ isActive }) =>
                                isActive ? "bg-gray-700" : undefined} className="text-white rounded-lg p-2 hover:bg-gray-700" to="/voucher">Voucher</NavLink>
                            <NavLink style={({ isActive }) =>
                                isActive ? "bg-gray-700" : undefined} className="text-white rounded-lg p-2 hover:bg-gray-700" to="/testai">Test Ai</NavLink>
                            <NavLink style={({ isActive }) =>
                                isActive ? "bg-gray-700" : undefined} className="text-white rounded-lg p-2 hover:bg-gray-700" to="/trainingai">Training Ai</NavLink>


                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}
