import React, { useEffect, useState } from 'react'
import { getTestAiData, getTestAiStat } from '../api/testAi/testai'
import { setAllTestAi } from '../redux/actions/testAiAction'
import { useDispatch, useSelector } from 'react-redux'
import TestAiList from '../components/list/TestAiList'
import { RefreshIcon } from '@heroicons/react/solid'

function TestAi() {

    // state
    const [allTest, setAllTest] = useState()
    const [stat, setStat] = useState()


    //redux
    const dispatch = useDispatch()
    const token = useSelector(state => state.user.token)


    const getTest = async () => {
        setAllTest()
        try {
            const data = await getTestAiData(token)
            setAllTest(data)
            dispatch(setAllTestAi(data))
   
        } catch (e) {
            // eslint-disable-next-line
            throw "Error"
        }

    }

    const getTestStat = async () => {
        try {
            const stat = await getTestAiStat(token)
      
            setStat(stat)
        } catch (e) {
            // eslint-disable-next-line
            throw "Error"
        }
    }

    useEffect(() => {
        try {
            getTest()
            getTestStat()
        } catch (e) {
            console.log(e)
        }
    }, [])


    return (
        <div>

            {
                !allTest ? <div className='text-center py-16 animate-pulse text-xl flex justify-center items-center'><RefreshIcon className='h-6 mr-2 animate-spin' />Loading...</div> :

                    <section className='mx-auto max-w-6xl'>
                        {
                            !stat ? <></> :
                                <div className='grid grid-cols-3 gap-4 mt-4 mx-4 md:mx-4'>
                                    <div className='flex items-center p-8 shadow-xl rounded-lg'>
                                        Tot. {stat.countTestAiAll}
                                    </div>
                                    <div className='flex bg-red-400 text-white items-center p-8 shadow-xl rounded-lg'>

                                        Da fare: {stat.countTestAiNotAnswered}
                                    </div>

                                    <div className='flex bg-green-500 text-white items-center p-8 shadow-xl rounded-lg'>

                                        Fatti: {stat.countTestAiAnswered}
                                    </div>
                                </div>
                        }
                        <div className='flex justify-end mx-4 md:mx-4 mt-4'>
                            <button onClick={() => {getTest(); getTestStat()}} className='bg-gray-50 p-4 flex rounded-lg'><RefreshIcon className='h-6 pr-2' />Refresh</button>
                        </div>
                        <div className='mx-4 md:mx-4 py-4 flex flex-col space-y-4'>
                            {
                                allTest.map((test) => {

                                    return (
                                        <TestAiList key={test._id} id={test._id} name={test.name} surname={test.surname} email={test.email} time={test.createdAt} url={test.urlImage[0].base} />
                                    )
                                })
                            }
                        </div>
                    </section>
            }
        </div>
    )
}

export default TestAi