
const BASE_URL = process.env.REACT_APP_BASE_URL

export const getTrainingAiData = async (token) => {

    const response = await fetch(BASE_URL + "/trainingTestAiCorrect/all", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
        
    }).catch((error) => {
        console.log(error);
        return error
    });

    if (response.status === 200) {
        const data = await response.json()
        return data;
    } else {
        // eslint-disable-next-line
        throw "Malformed call"
    }
};


export const getSingleTrainingAiData = async (token,id) => {

    const response = await fetch(BASE_URL + "/trainingTestAiCorrect/"+id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
    }).catch((error) => {
        console.log(error);
        return error
    });

    if (response.status === 200) {
        const data = await response.json()
        return data;
    } else {
        // eslint-disable-next-line
        throw "Malformed call"
    }
};


export const putSingleTrainingAi = async (token,id,season,underseason) => {

    const data = {
        season:season,
        underSeason:underseason
    }

    const response = await fetch(BASE_URL + "/trainingTestAiCorrect/"+id, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(data),
    }).catch((error) => {
        console.log(error);
        return error
    });

    if (response.status === 200) {
        const data = await response.json()
        return data;
    } else {
        // eslint-disable-next-line
        throw "Malformed call"
    }
};


export const getTrainingAiStat = async (token) => {

    const response = await fetch(BASE_URL + "/trainingTestAiCorrect/statistics", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
    }).catch((error) => {
        console.log(error);
        return error
    });

    if (response.status === 200) {
        const data = await response.json()
        return data;
    } else {
        // eslint-disable-next-line
        throw "Malformed call"
    }
};

export const deleteTrainingDataById = async (token, id) => {

    const response = await fetch(BASE_URL + "/trainingTestAiCorrect/"+id, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        }
    }).catch((error) => {
        console.log(error);
        return "error";
    });

    if (response.status === 200) {
        const result = await response.json()
        return result;
    } else {
        return "error";
    }
};