import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"

// components to check if JWT is valid and save user to REDUX
import Cookies from 'js-cookie'
import { checkJWT } from '../../api/auth/auth'
import { setLogging, setToken, setUser } from '../../redux/actions/userAction'


function LoginWrapper({ children }) {

    const [checking, setChecking] = useState(true)

    let token = Cookies.get("khooa-backoffice")

    //REDUX
    const dispatch = useDispatch()

    useEffect(async () => {

        if (token) {
            const response = await checkJWT(token);
            if (response == "error") {
                dispatch(setLogging(false))
            } else {
                dispatch(setLogging(true))
                dispatch(setUser(response.user))
                dispatch(setToken(token))
            }
        } else {
            dispatch(setLogging(false))
        }
        setChecking(false)

    }, [])

    //finchè la chiamata al JWT è in atto mostro un Loader
    if (checking) {
        return (
            <div className="flex flex-col justify-center items-center min-h-screen">
                <label className="font-extrabold text-3xl">Loading</label>
            </div>
        )
    }

    // se è finito il check del JWT ritorno tutta l'app
    return (
        <div>
            {children}
        </div>
    )
}

export default LoginWrapper
