import { ActionTypes } from '../constants/actionsType'

const intialState = {
    user: null,
    loggin: false,
    token: null
};

export const userReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_USER:
            return { ...state, user: payload };
        case ActionTypes.SET_LOGGING:
            return { ...state, loggin: payload }
        case ActionTypes.SET_TOKEN:
            return { ...state, token: payload }
        default:
            return state;
    }
};