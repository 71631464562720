export const getFirebase = async () => {

    const response = await fetch("https://lawxr35gq1.execute-api.eu-central-1.amazonaws.com/default/getFirebase", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).catch((error) => {
        console.log(error);
        return "error";
    });

    if (response.status === 200) {
        const data = await response.json()
        return data;
    } else {
        return "error";
    }
};