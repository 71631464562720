import React, { useEffect, useState } from 'react'

// redux
import { useSelector } from 'react-redux'
import { getCountVoucher } from '../api/consulting/voucher'
import { getFirebase } from '../api/kpi/kpi'
import { getTestAiStat } from '../api/testAi/testai'

function Home() {

    //redux
    const user = useSelector(state => state.user.user)
    const token = useSelector(state => state.user.token)

    //state
    const [kpi, setKPI] = useState()
    const [testDifferent, setTestDifference] = useState()
    const [voucherNumber, setVoucherNumber] = useState()
    const [testAi, setTestAi] = useState()


    useEffect(() => {
        const fetch = async () => {

            const data = await getFirebase()
            //const voucher = await getCountVoucher()
            try {
                const test = await getTestAiStat(token)
                setTestAi(test)
            } catch (e) {
                console.log(e)
            }
            let test = Object.values(data.TestSeason);
            setKPI(Object.values(data.TestSeason))
            setTestDifference(test[test.length - 2] - test[test.length - 3])
            // setVoucherNumber(voucher)

        }
        fetch()
    }, [])


    return (
        <div className="flex flex-col items-center justify-center py-10 mx-5">
            <label className="font-extrabold text-center text-4xl mb-5">Ciao {user.name}, buon lavoro! </label>
            <div className="flex flex-col justify-center items-center shadow-md bg-gray-100 p-3 min-w-full rounded-lg gap-3">
                <h2 className='text-2xl font-semibold'>Test</h2>
                <div className='flex gap-4'>
                    <div className="flex flex-col bg-white p-5 rounded-lg shadow-md">
                        <label>Test quotidiani: </label>
                        {kpi ? (<label className="text-3xl font-extrabold">{kpi[kpi.length - 2]}</label>) : (<label>...</label>)}
                        {testDifferent ? (
                            testDifferent < 0 ? (<div><label className="text-red-400">{testDifferent}</label></div>)
                                : (<div><label className="text-green-400">+{testDifferent}</label></div>)) :
                            (<div></div>)}
                    </div>

                    <div className="flex flex-col bg-white p-5 rounded-lg shadow-md">
                        <label>Test totali: </label>
                        {kpi ? (<label className="text-3xl font-extrabold">{kpi[kpi.length - 1]}</label>) : (<label>...</label>)}
                    </div>
                </div>



            </div>

            <div className="flex flex-col justify-center items-center shadow-md bg-gray-100 p-3 min-w-full rounded-lg gap-3">

                <h2 className='text-2xl font-semibold'>Consulenze</h2>
                <div className="flex flex-col bg-white p-5 rounded-lg shadow-md">
                    <label>Voucher venduti:</label>
                    {voucherNumber ? (<label className="text-3xl font-extrabold">{voucherNumber.countBookingSession}</label>) : (<label>...</label>)}
                </div>

            </div>

            <div className="flex flex-col justify-center items-center shadow-md bg-gray-100 p-3 min-w-full rounded-lg gap-3">

                <h2 className='text-2xl font-semibold'>Test AI</h2>
                <div className="flex flex-col bg-white p-5 rounded-lg shadow-md">
                    <label>Test fatti:</label>
                    {testAi ? (<label className="text-3xl font-extrabold">{testAi.countTestAiAll}</label>) : (<label>...</label>)}
                </div>

            </div>
        </div>
    )
}

export default Home
