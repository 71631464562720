import React from 'react'
import ReactTimeAgo from 'react-time-ago'
import TimeAgo from 'javascript-time-ago'
import it from 'javascript-time-ago/locale/it.json'
import { useHistory } from 'react-router-dom'


TimeAgo.addDefaultLocale(it)




function TestAiList({id,name,surname,email,url,time}) {

const history = useHistory()
var created_date = new Date(time);

  return (
    <div onClick={()=>history.push("/testai/"+id)} className='flex items-center bg-gray-50 p-2 rounded-lg cursor-pointer'>
        <img className='w-16 rounded-lg' src={url}/>
        <div className='flex flex-col justify-center px-4 border-r-2'>
            <p>{name}</p>
            <p>{surname}</p>
        </div>
        <div className='flex flex-col justify-center pl-4'>
            <p>{email}</p>
            <ReactTimeAgo  date={created_date} locale="it-IT"/>
        </div>
    </div>
  )
}

export default TestAiList