import { Notify } from 'notiflix';
import React, {useEffect} from 'react';
import {
    Route,
    Redirect
} from 'react-router-dom';

function PrivateRoute({
    isAuthenticated,
    component: Component,
    ...rest
}) {
    
    useEffect(() => {
        if(!isAuthenticated){
            Notify.failure("Effettua il login per accedere a questa pagina!")
        }
    })

    return (
        <Route {...rest} component={(props) => (
            isAuthenticated ? (
                <div>
                    <Component {...props} />
                </div>
            ) : (
                <Redirect to="/login" />
            )
        )} />
    )
}

export default PrivateRoute