
const BASE_URL = process.env.REACT_APP_BASE_URL

export const getTestAiData = async (token) => {

    const response = await fetch(BASE_URL + "/testAi/all", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
        
    }).catch((error) => {
        console.log(error);
        return error
    });

    if (response.status === 200) {
        const data = await response.json()
        return data;
    } else {
        // eslint-disable-next-line
        throw "Malformed call"
    }
};


export const getSingleTestAiData = async (token,id) => {

    const response = await fetch(BASE_URL + "/testAi/"+id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
    }).catch((error) => {
        console.log(error);
        return error
    });

    if (response.status === 200) {
        const data = await response.json()
        return data;
    } else {
        // eslint-disable-next-line
        throw "Malformed call"
    }
};


export const putSingleTestAi = async (token,id,season,underseason) => {

    const data = {
        season:season,
        underSeason:underseason
    }

    const response = await fetch(BASE_URL + "/testAi/"+id, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(data),
    }).catch((error) => {
        console.log(error);
        return error
    });

    if (response.status === 200) {
        const data = await response.json()
        return data;
    } else {
        // eslint-disable-next-line
        throw "Malformed call"
    }
};


export const getTestAiStat = async (token) => {

    const response = await fetch(BASE_URL + "/testAi/statistics", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
    }).catch((error) => {
        console.log(error);
        return error
    });

    if (response.status === 200) {
        const data = await response.json()
        return data;
    } else {
        // eslint-disable-next-line
        throw "Malformed call"
    }
};