import React from 'react'
import ProductCardAll from '../components/card/ProductCardAll'

function SortingDress() {
    return (
        <div>
            <div className="bg-white shadow-sm rounded-xl p-2">
                <h1 className="text-center font-bold my-5 text-3xl">Smistamento completo prodotti</h1>

                <div className="mt-4 flex flex-col space-y-2">
                    <ProductCardAll />
                </div>

            </div>
        </div>
    )
}

export default SortingDress
