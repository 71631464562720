import React from 'react'
import ProductCardBrand from '../components/card/ProductCardBrand'

function SortingDressBrand() {
    return (
        <div>
            <div className="bg-white shadow-sm rounded-xl p-2">
                <h1 className="text-center font-bold my-5 text-3xl">Smistamento prodotti per brand</h1>

                <div className="mt-4 flex flex-col space-y-2">
                    <ProductCardBrand />
                </div>

            </div>
        </div>
    )
}

export default SortingDressBrand
