import { ActionTypes } from "../constants/actionsType";

export const setUser = (user) => {
  return {
    type: ActionTypes.SET_USER,
    payload: user,
  };
};

export const setLogging = (loggin) => {
  return {
    type: ActionTypes.SET_LOGGING,
    payload: loggin
  };
};

export const setToken = (token) => {
  return {
    type: ActionTypes.SET_TOKEN,
    payload: token
  };
};